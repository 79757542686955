import { useMemo, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Box,
  Typography,
  Stack,
  CircularProgress,
  IconButton,
  MenuItem,
  TextField,
  Tooltip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import {
  InfoOutlined,
  FiberManualRecord,
  Build,
  ExpandMore,
} from '@mui/icons-material';

import {
  StartingIcon,
  DeleteIcon,
  OracleSmallIcon,
  PostgresSmallIcon,
  MySQLSmallIcon,
  StoppingIcon,
  FailedIcon,
  SwitchOverIcon,
  SQLServerIconSmall,
  BackingUpIcon,
  CircularWarningIcon,
} from 'assets/icons-v2';
import STATUS, { StatusString, SubStatus, TicketStatus } from './Status';
import { shouldEnableInstanceResizeFeature } from './featureFlags';
import moment from 'moment';
import { getDatabaseDRCount } from './instances/utils/getDatabaseDRCount';
import { getDatabaseDCCount } from './instances/utils/getDatabaseDCCount';
import { getDatabaseHANodeCount } from './instances/utils/getDatabaseHANodeCount';
import { styles } from 'assets/scss/style-templates';
import SchedulingIcon from 'assets/icons-v2/SchedulingIcon';
import { nextScheduleAction } from './schedule/utils';
import {
  StatusBackgroundColor,
  StatusIcons,
  StatusBorderColor,
  StatusTextColor,
} from 'constants/statusConfigs';
import StatusLabel from 'common/custom-components/lib/components/StatusLabel';

export const useStyles = () => {
  const theme = useTheme();
  return {
    intgTile: {
      width: 400,
      height: 200,
      marginTop: theme.spacing(2),
      marginRight: theme.spacing(2),
      border: `1px solid ${styles.color.lighterShade}`,
      borderRadius: '10px',
      position: 'relative',
    },
    tagIcon: {
      backgroundColor: styles.color.greyBackground,
      color: styles.color.darkShade,
    },
    // icon: { color: styles.color.darkShade },
    intgSkeleton: {
      marginTop: theme.spacing(2),
      marginRight: theme.spacing(2),
      background: 'rgba(151, 151, 151, 0.25)',
    },
    intgButton: {
      position: 'absolute',
      bottom: theme.spacing(1),
      right: theme.spacing(1),
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    monSkeleton: {
      marginTop: theme.spacing(2),
      marginRight: theme.spacing(2),
      borderRadius: '10px',
      background: 'rgba(151, 151, 151, 0.25)',
    },
    monTile: {
      width: '100%',
      minWidth: 400,
      maxWidth: 800,
      // marginTop: theme.spacing(2),
      // marginRight: theme.spacing(2),
      // paddingRight: theme.spacing(1),
      padding: '16px',
      borderRadius: '10px',
      border: `1px solid ${styles.color.lighterShade}`,
    },
    toggleButton: {
      '& .MuiToggleButton-root': {
        backgroundColor: '#FFFFFFAA !important',
        color: `${styles.color.textBoxLabel}`,
        // width: '120px',
      },
      '& .MuiToggleButton-root.Mui-selected': {
        // backgroundColor: '#E8F5FF !important',
        color: `${styles.color.baseBackground}`,
        backgroundColor: `${styles.color.darkShade} !important`,
      },
    },
    mainButton: {
      border: 'none',
      // borderRadius: '10px',
      minWidth: '150px',
      '&:hover': {
        border: 'none',
        // borderRadius: '10px',
        minWidth: '150px',
        cursor: 'pointer',
      },
    },
    resetOverviewBtn: {
      border: 'none',
      // borderRadius: '10px',
      width: '100%',
      '&:hover': {
        border: 'none',
        // borderRadius: '10px',
        width: '100%',
        cursor: 'pointer',
      },
    },
    boldText: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    headingText: {
      fontWeight: theme.typography.fontWeightMedium,
      paddingBottom: 2,
      fontSize: '16px',
    },
    boldTextLight: {
      fontWeight: theme.typography.fontWeightMedium,
      color: styles.color.monotoneDarker,
    },
    filter: {
      width: 250,
      '& .MuiOutlinedInput-root': {
        borderRadius: '20px',
      },
      '& .MuiFilledInput-root': {
        borderRadius: '24px',
      },
    },
    timePicker: {
      width: 430,
      '& .MuiOutlinedInput-root': {
        borderRadius: '20px',
      },
      '& .MuiFilledInput-root': {
        borderRadius: '24px',
      },
    },
    contentBox: {
      boxSizing: 'border-box',
      borderRadius: '8px',
      boxShadow:
        ' 0px 1px 4px rgba(31, 41, 55, 0.06), 0px 1px 6px rgba(31, 41, 55, 0.1); !important',
      background: 'white',
      marginBottom: '20px',
    },
    accordionBox: {
      boxSizing: 'border-box',
      borderRadius: '4px',
      boxShadow:
        '0px 1px 4px rgba(31, 41, 55, 0.06), 0px 1px 6px rgba(31, 41, 55, 0.1) !important',
      marginBottom: '20px',
    },
    accordionSummaryBox: {
      backgroundColor: '#F7F7F7',
      // boxShadow: '0px 1px 4px rgba(31, 41, 55, 0.06), 0px 1px 6px rgba(31, 41, 55, 0.1) !important',
      borderRadius: '4px',
    },
    progress: {
      color: 'white',
    },
    innerContentBox: {
      borderRadius: '10px',
      border: `1px solid ${styles.color.lighterShade}`,
      background: 'white',
    },
    filesDisplayBox: {
      background: styles.color.greyBackground,
      height: '100%',
      minWidth: 250,
    },
    logsTab: {
      paddingLeft: '16px',
      paddingTop: '0px',
      paddingBottom: '0px',
      '&.Mui-selected': {
        backgroundColor: styles.color.greyBackground,
      },
    },
    nwInfoBox: {
      width: '50%',
      minWidth: '680px',
    },
    computeInfoBox: {
      width: '50%',
      minWidth: 400,
      maxWidth: 500,
      minHeight: 150,
      padding: theme.spacing(2),
      boxSizing: 'border-box',
      borderRadius: '10px',
    },
    instanceNwInfoBox: {
      minWidth: 600,
      maxWidth: 600,
      minHeight: 200,
      padding: theme.spacing(2),
      // marginTop: theme.spacing(2),
      boxSizing: 'border-box',
      borderRadius: '10px',
      // border: `1px solid ${styles.color.lighterShade}`,
    },
    compactBox: {
      padding: '5px 10px',
      fontSize: '14px',
    },
    chipsBox: {
      padding: '5px 10px',
      fontSize: '14px',
      border: '1px solid red',
    },

    dbInfo: {
      background: '#EEEEEE',
      borderRadius: '10px',
    },
    connectionInfoEdit: { width: '30%', justifyContent: 'flex-end' },
    lightText: {
      fontWeight: theme.typography.fontWeightRegular,
    },
    engineLogo: {
      width: 100,
      height: 32,
    },
    backButton: {
      alignSelf: 'self-start',
    },
    linkIcon: {
      width: 48,
      marginRight: theme.spacing(1),
    },
    button: {
      marginRight: theme.spacing(1),
    },
    skeleton: {
      marginTop: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    deleteBox: {
      border: '1px solid #E1E5EA',
      borderRadius: '5px',
      cursor: 'pointer',
      transition: 'border 200ms linear',
    },
    input: {
      marginTop: theme.spacing(2),
      width: 350,
    },
    deleteIcon: {
      '--icon-color': styles.color.red,
    },
    liveLogs: {
      color: styles.color.green,
    },
    icon: {
      color: styles.color.darkShade,
      cursor: 'pointer',
    },
    UpInstanceDot: {
      height: '12px',
      width: '12px',
      color: styles.color.green,
    },
    DownInstanceDot: {
      height: '12px',
      width: '12px',
      color: styles.color.red,
    },
    StoppedInstanceDot: {
      height: '12px',
      width: '12px',
      color: styles.color.warn,
    },
    statusBox: {
      width: 'fit-content',
      paddingLeft: '8px',
      paddingRight: '8px',
      paddingTop: '3px',
      paddingBottom: '3px',
      borderRadius: '4px',
      height: 'fit-content',
    },
  };
};

export function GetInstanceStatus({ status }) {
  return (
    <StatusLabel
      backgroundColor={StatusBackgroundColor[status]}
      textColor={StatusTextColor[status]}
      border={StatusBorderColor[status]}
      Icon={StatusIcons[status]}
      text={StatusString[status] || status}
    />
  );
}

export function GetNextScheduleAction({ status, display, dateTime }) {
  const classes = useStyles();

  switch (status) {
    case nextScheduleAction.start:
      return (
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={1}
        >
          <Stack
            height="12px"
            width="2px"
            sx={{
              backgroundColor: styles.color.green,
              borderRadius: '8px',
            }}
          />
          <Typography
            display={display}
            color="#08080D"
            fontSize="12px"
            fontWeight="400"
            lineHeight="16px"
          >
            Start on {dateTime}
          </Typography>
        </Stack>
      );

    case nextScheduleAction.stop:
      return (
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={1}
        >
          <Stack
            height="12px"
            width="2px"
            sx={{
              backgroundColor: styles.color.warn,
              borderRadius: '8px',
            }}
          />
          <Typography
            display={display}
            color="#08080D"
            fontSize="12px"
            fontWeight="400"
            lineHeight="16px"
          >
            Stop on {dateTime}
          </Typography>
        </Stack>
      );

    case nextScheduleAction.delete:
      return (
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={1}
        >
          <Stack
            height="12px"
            width="2px"
            sx={{
              backgroundColor: styles.color.red,
              borderRadius: '8px',
            }}
          />
          <Typography
            display={display}
            color="#08080D"
            fontSize="12px"
            fontWeight="400"
            lineHeight="16px"
          >
            Delete on {dateTime}
          </Typography>
        </Stack>
      );
    default:
      return null;
  }
}

export function GetServiceStatus({
  status,
  instances,
  sx: overrideStyles,
  customStatus,
}) {
  const classes = useStyles();
  const getInstanceDot = (instanceStatus) => {
    switch (instanceStatus) {
      case STATUS.Up:
        return <FiberManualRecord sx={classes.UpInstanceDot} />;
      case STATUS.Down:
        return <FiberManualRecord sx={classes.DownInstanceDot} />;
      case STATUS.Stopped:
        return <FiberManualRecord sx={classes.StoppedInstanceDot} />;
      default:
        return null;
    }
  };

  switch (status) {
    case STATUS.Ready:
      return (
        <Stack
          direction="row"
          alignItems="center"
          sx={{
            ...classes.statusBox,
            backgroundColor: '#F0FCF0',
            border: `0.5px solid ${styles.color.green}`,
            borderRadius: '4px',
          }}
        >
          <Typography color={styles.color.green} mr={1}>
            Ready
          </Typography>
          {instances?.map((i) => getInstanceDot(i.status))}
        </Stack>
      );
    case STATUS.Stopped:
      return (
        <Stack
          direction="row"
          alignItems="center"
          sx={{
            ...classes.statusBox,
            backgroundColor: '#FEF2D8',
            border: `0.5px solid ${styles.color.warn}`,
            borderRadius: '4px',
          }}
        >
          <Typography color={styles.color.warn} mr={1}>
            Stopped
          </Typography>
          {instances?.map(() => getInstanceDot('STOPPED'))}
        </Stack>
      );
    case STATUS.Starting:
      return (
        <Stack direction="row" alignItems="center" spacing={1}>
          <StartingIcon />
          <Typography color="primary" sx={overrideStyles?.startingText}>
            {customStatus || 'Starting'}
          </Typography>
        </Stack>
      );
    case STATUS.Stopping:
      return (
        <Stack direction="row" alignItems="center" spacing={1}>
          <StoppingIcon />
          <Typography color="primary">Stopping</Typography>
        </Stack>
      );
    case STATUS.Deleting:
      return (
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          sx={{ animation: 'blinker 2s linear infinite' }}
        >
          <DeleteIcon width={16} style={{ '--icon-color': styles.color.red }} />
          <Typography color={styles.color.red}>Deleting</Typography>
        </Stack>
      );
    case STATUS.Deleted:
      return (
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          sx={{ animation: 'blinker 2s linear infinite' }}
        >
          <DeleteIcon width={16} style={{ '--icon-color': styles.color.red }} />
          <Typography color={styles.color.red}>Deleted</Typography>
        </Stack>
      );
    case STATUS.Failed:
      return (
        <Stack direction="row" alignItems="center" spacing={1}>
          <FailedIcon />
          <Typography>Failed</Typography>
        </Stack>
      );
    case STATUS.Creating:
      return (
        <Stack direction="row" alignItems="center" spacing={1}>
          <CircularProgress size={16} />
          <Typography color="primary">{customStatus || 'Creating'}</Typography>
        </Stack>
      );
    case STATUS.Switching:
      return (
        <Stack direction="row" alignItems="center" spacing={1}>
          <SwitchOverIcon darkBg={false} />
          <Typography
            sx={overrideStyles?.startingText}
            color={
              customStatus?.includes('Ready')
                ? styles.color.green
                : customStatus?.includes('Operation')
                ? styles.color.red
                : 'primary'
            }
          >
            {customStatus || 'Switching'}
          </Typography>
        </Stack>
      );

    case STATUS.Rebuilding:
      return (
        <Stack direction="row" alignItems="center" spacing={1}>
          <Build color="primary" sx={{ width: '20px' }} />
          <Typography color="primary">Rebuilding</Typography>
        </Stack>
      );

    case STATUS.Degraded:
      return (
        <Stack
          direction="row"
          alignItems="center"
          sx={{
            ...classes.statusBox,
            backgroundColor: '#FFEBEB',
            border: '0.5px solid #EC373C',
            borderRadius: '4px',
          }}
        >
          <Typography color={styles.color.red} mr={1}>
            Degraded
          </Typography>
          {instances?.map((i) => getInstanceDot(i.status))}
        </Stack>
      );

    case STATUS.DegradedHealing:
      return (
        <Stack
          direction="row"
          alignItems="center"
          sx={{
            ...classes.statusBox,
            backgroundColor: '#EEEEEE',
            border: '0.5px solid #979797',
            borderRadius: '4px',
          }}
        >
          <Stack
            mr={1}
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Typography color={styles.color.textBoxLabel} display="inline">
              Degraded{' '}
              <Typography color="#979797" display="inline">
                (Healing)
              </Typography>
            </Typography>
          </Stack>
          {instances?.map((i) => getInstanceDot(i.status))}
        </Stack>
      );

    case STATUS.Down:
      return (
        <Stack
          direction="row"
          alignItems="center"
          sx={{
            ...classes.statusBox,
            backgroundColor: '#FFEBEB',
            border: '0.5px solid #EC373C',
            borderRadius: '4px',
          }}
        >
          <Typography color={styles.color.red} mr={1}>
            Down
          </Typography>
          {instances?.map((i) => getInstanceDot(i.status))}
        </Stack>
      );
    case STATUS.REFRESH_FAILED:
      return (
        <Stack
          direction="row"
          alignItems="center"
          sx={{
            ...classes.statusBox,
            backgroundColor: '#FFEBEB',
            border: '0.5px solid #EC373C',
            borderRadius: '4px',
          }}
        >
          <Typography color={styles.color.red} mr={1}>
            Refresh Failed
          </Typography>
          {instances?.map((i) => getInstanceDot(i.status))}
        </Stack>
      );

    case STATUS.PARAMETER_PROFILE_UPDATE_FAILED:
      return (
        <Stack
          direction="row"
          alignItems="center"
          sx={{
            ...classes.statusBox,
            backgroundColor: '#FFEBEB',
            border: '0.5px solid #EC373C',
            borderRadius: '4px',
          }}
        >
          <Typography color={styles.color.red} mr={1}>
            Parameter Profile Update Failed
          </Typography>
          {instances?.map((i) => getInstanceDot(i.status))}
        </Stack>
      );
    case STATUS.UNDER_MAINTENANCE:
      return (
        <Stack
          direction="row"
          alignItems="center"
          sx={{
            ...classes.statusBox,
            backgroundColor: '#FFEBEB',
            border: '0.5px solid #EC373C',
            borderRadius: '4px',
          }}
        >
          <Typography color={styles.color.red} mr={1}>
            Under Maintenance
          </Typography>
          {instances?.map((i) => getInstanceDot(i.status))}
        </Stack>
      );
    case STATUS.REFRESHING:
      return (
        <Stack
          direction="row"
          alignItems="center"
          sx={{
            ...classes.statusBox,
            backgroundColor: '#FFEBEB',
            border: '0.5px solid #EC373C',
            borderRadius: '4px',
          }}
        >
          <Typography color={styles.color.red} mr={1}>
            Refreshing
          </Typography>
          {instances?.map((i) => getInstanceDot(i.status))}
        </Stack>
      );
    case STATUS.UPDATING_PARAMETER_PROFILE:
      return (
        <Stack
          direction="row"
          alignItems="center"
          sx={{
            ...classes.statusBox,
            backgroundColor: '#FFEBEB',
            border: '0.5px solid #EC373C',
            borderRadius: '4px',
          }}
        >
          <Typography color={styles.color.red} mr={1}>
            Updating Parameter Profile
          </Typography>
          {instances?.map((i) => getInstanceDot(i.status))}
        </Stack>
      );

    case STATUS.Inactive:
      return (
        <Stack
          direction="row"
          alignItems="center"
          sx={{
            ...classes.statusBox,
            backgroundColor: '#FFEBEB',
            border: '0.5px solid #EC373C',
            borderRadius: '4px',
          }}
        >
          <Typography color={styles.color.red} mr={1}>
            Inactive
          </Typography>
        </Stack>
      );

    default:
      return (
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography color="primary">
            {StatusString[status] || status?.replace('_', ' ')}
          </Typography>
        </Stack>
      );
  }
}

export function GetServiceSubstatus(props) {
  const { upcomingScheduledActions, contextInfo, showPillDetails } = props;
  const [showSubStatus, setShowSubStatus] = useState(false);
  const [showScheduleActions, setShowScheduleActions] = useState(false);

  const ScheduleAccordionDetails = () => (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="flex-start"
      spacing={1}
    >
      {upcomingScheduledActions?.startStop !== null && (
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={0}
        >
          <Typography fontSize="16px" display="inline">
            <GetNextScheduleAction
              status={upcomingScheduledActions?.startStop?.action}
              display="inline"
              dateTime={moment(upcomingScheduledActions?.startStop?.at).format(
                'MMM DD YYYY, hh:mm A',
              )}
            />
          </Typography>
        </Stack>
      )}
      {upcomingScheduledActions?.delete !== null && (
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={0}
        >
          <Typography display="inline" fontSize="16px">
            <GetNextScheduleAction
              status="delete"
              display="inline"
              dateTime={moment(upcomingScheduledActions?.delete?.at).format(
                'MMM DD YYYY, hh:mm A',
              )}
            />
          </Typography>
        </Stack>
      )}
    </Stack>
  );

  const subStatusTitle = (substatus) => {
    switch (substatus) {
      case SubStatus.BackingUp:
        return 'Snapshot is in progress';
      case SubStatus.StartFailed:
        return 'Failed due to an unexpected error';
      case SubStatus.SwitchoverFailed:
        return 'Failed due to an unexpected error';
      default:
        return substatus;
    }
  };

  const pillSubStatusTitle = (substatus) => {
    switch (substatus) {
      case SubStatus.BackingUp:
        return 'Snapshot in progress';
      case SubStatus.StartFailed:
        return 'Start failed';
      case SubStatus.SwitchoverFailed:
        return 'Switchover failed';
      case SubStatus.REFRESH_FAILED:
        return 'Refresh failed';
      case SubStatus.UPDATING_PARAMETER_PROFILE:
        return 'Updating parameter profile';
      case SubStatus.PARAMETER_PROFILE_UPDATE_FAILED:
        return 'Parameter profile update failed';
      default:
        return substatus?.replaceAll('_', ' ');
    }
  };

  const subStatusDescription = (substatus, desc) => {
    switch (substatus) {
      case SubStatus.BackingUp:
        return desc;
      case SubStatus.StartFailed:
        return `${desc}. We are sorry for the inconvenience. Tessell Support might already be looking into the issue.  Please raise a ticket to get immediate resolution.`;
      case SubStatus.SwitchoverFailed:
        return `${desc}. We are sorry for the inconvenience. Tessell Support might already be looking into the issue.  Please raise a ticket to get immediate resolution.`;
      default:
        return contextInfo?.description;
    }
  };

  const mockData = [
    {
      icon:
        contextInfo?.subStatus === SubStatus.BackingUp ? (
          <BackingUpIcon />
        ) : (
          <CircularWarningIcon />
        ),
      title: subStatusTitle(contextInfo?.subStatus),
      pillTitle: pillSubStatusTitle(contextInfo?.subStatus),
      description: subStatusDescription(
        contextInfo?.subStatus,
        contextInfo?.description,
      ),
      ticketId: '',
      visible: !!contextInfo,
      pillDetails: showPillDetails,
      type: 'contextInfo',
    },
    {
      icon: <SchedulingIcon darkBg={false} width="12" height="12" />,
      title: 'Upcoming Schedules',
      description: <ScheduleAccordionDetails />,
      visible: !!upcomingScheduledActions,
      pillDetails: false,
      type: 'scheduleActions',
    },
  ];

  const CollapsableStack = ({ data }) => (
    <Accordion
      disableGutters
      elevation={0}
      expanded={
        data?.type === 'contextInfo' ? showSubStatus : showScheduleActions
      }
      onChange={() =>
        data?.type === 'contextInfo'
          ? setShowSubStatus(!showSubStatus)
          : setShowScheduleActions(!showScheduleActions)
      }
      sx={{
        borderRadius: '8px !important',
        '&:before': {
          backgroundColor: 'transparent !important',
        },
      }}
    >
      <AccordionSummary
        sx={{
          backgroundColor: 'white',
          boxShadow: 'none',
          borderRadius: '8px !important',
        }}
        expandIcon={<ExpandMore />}
      >
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={1}
        >
          <Stack
            height="20px"
            width="20px"
            justifyContent="center"
            alignItems="center"
          >
            {data?.icon}
          </Stack>
          <Typography
            fontSize="14px"
            fontWeight="600"
            lineHeight="20px"
            color="#08080D"
          >
            {data?.title}
          </Typography>
        </Stack>
      </AccordionSummary>
      {/* <Slide in={showDetails}> */}
      <AccordionDetails
        sx={{
          boxShadow: 'none',
          borderRadius: '8px !important',
        }}
      >
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={1}
        >
          <Typography
            fontSize="12px"
            fontWeight="700"
            lineHeight="16px"
            color="#666666"
            ml="28px"
          >
            {data?.description}
          </Typography>
        </Stack>
      </AccordionDetails>
      {/* </Slide> */}
    </Accordion>
  );

  const SubstatusInfo = () => (
    <Stack>
      {mockData.map(
        (data) => data?.visible && <CollapsableStack data={data} />,
      )}
    </Stack>
  );

  const SubStatusPill = () => (
    <Stack
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing="4px"
      px="4px"
      sx={{
        border: '0.5px solid #CCCCCC',
        borderRadius: '24px',
        height: '24px',
        maxHeight: '24px',
      }}
    >
      {mockData.map(
        (data) =>
          data?.visible && (
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Stack
                width="16px"
                height="16px"
                justifyContent="center"
                alignItems="center"
              >
                {data?.icon}
              </Stack>
              <Typography
                fontWeight="400"
                fontSize="12px"
                lineHeight="16px"
                display={data?.pillDetails ? 'block' : 'none'}
                mx="4px"
              >
                {data?.pillTitle}
              </Typography>
              <Stack
                width="2px"
                height="12px"
                display={
                  data?.type === 'contextInfo' && !!upcomingScheduledActions
                    ? 'block'
                    : 'none'
                }
                sx={{
                  backgroundColor: '#E6E6E6',
                  borderRadius: '10px',
                  marginLeft: data?.pillDetails ? '2px' : '4px',
                }}
              />
            </Stack>
          ),
      )}
    </Stack>
  );

  return (
    <Tooltip
      placement="right-start"
      arrow
      title={<SubstatusInfo />}
      PopperProps={{
        sx: {
          '& .MuiTooltip-tooltip': {
            backgroundColor: 'white',
            width: '310px',
            height: '100%',
            color: 'black',
            borderRadius: '8px',
            padding: 0,
            filter:
              'drop-shadow(0px 0px 6px rgba(31, 41, 55, 0.05)) drop-shadow(0px 6px 15px rgba(31, 41, 55, 0.1))',
          },
          '& .MuiTooltip-arrow': {
            color: 'white',
          },
        },
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [-10, 0],
            },
          },
        ],
      }}
    >
      <div>
        <SubStatusPill />
      </div>
    </Tooltip>
  );
}

export function GetEngineIcon({ engine, size }) {
  // : { engine: string, size: number }
  const customSize = size || 30;
  switch (engine) {
    case 'ORACLE':
      return <OracleSmallIcon width={customSize} height={customSize} />;
    case 'POSTGRESQL':
      return <PostgresSmallIcon width={customSize} height={customSize} />;
    case 'MYSQL':
      return <MySQLSmallIcon width={customSize} height={customSize} />;
    case 'SQLSERVER':
      // return  <SQLServerIconMicro height={40} />;
      return <SQLServerIconSmall zoom={1.5} />;
    default:
      return null;
  }
}

export function TruncatedLabelValue({
  label,
  value,
  sxClass,
  toolTipPlacement,
}) {
  const classes = useStyles();
  return (
    <Stack direction="row" alignItems="center" p={0.25}>
      <Typography sx={classes.boldText} width="max-content">
        {label}:
      </Typography>
      <Tooltip
        title={value?.length < 15 ? '' : value}
        placement={toolTipPlacement || 'right'}
      >
        <Typography
          pl={1}
          width="fit-content"
          sx={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            maxWidth: '150px',
            maxHeight: '30px',
            ...sxClass,
          }}
        >
          {value}
        </Typography>
      </Tooltip>
    </Stack>
  );
}

export const getLabelValue = (label, value, variant) => (
  <Box display="flex" flexDirection="row">
    <Typography color="textSecondary" variant={variant}>
      {label}
      :&nbsp;
    </Typography>
    <Typography variant={variant}>{value}</Typography>
  </Box>
);

export function DbInfoIcon(props) {
  const { onClick } = props;

  return (
    <IconButton
      onClick={(e) => onClick(e)}
      sx={{ minHeight: 0, minWidth: 0, padding: 0 }}
    >
      <InfoOutlined
        color="primary"
        sx={{ width: '20px' }}
        onClick={(e) => onClick(e)}
      />
    </IconButton>
  );
}

export const databaseRoleMap = {
  OWNER: 'CO_OWNER',
  CO_OWNER: 'CO_OWNER',
  READ_ONLY: 'READ_ONLY',
};

export function useIsFeatureEnabled() {
  // const { rolesMap } = useFetchUserRoles();
  // const { response: featureFlags } = useFetchFeatures();
  const featureFlags = {};
  const features = Cache?.get?.('features')
    ? JSON.parse(Cache.get('features'))['My Services'] || []
    : [];

  // List of valid state for updation of a db service.
  const validDBStateForGeneralUpdate = useMemo(
    () => [
      STATUS.Ready,
      STATUS.Stopped,
      STATUS.Stopping,
      STATUS.Starting,
      STATUS.Degraded,
      STATUS.DegradedHealing,
    ],
    [],
  );

  const allowedEngineForDR = useMemo(
    () => ['ORACLE', 'POSTGRESQL', 'MYSQL'],
    [],
  );

  return (database) => {
    const userRole = database?.loggedInUserRole;
    const dbStatus = database?.status;
    const dbCloud = database?.cloud;
    const dbEngineType = database?.engineType;
    const dbInstances = database?.instances;
    const isDbMultiAZ = database?.multiAZ;
    const isSSLEnabled = database?.serviceConnectivity?.enableSSL;

    // Check if service is highly available
    const isMultiAz = dbInstances?.length > 1 || isDbMultiAZ;

    // Check if the service is PostgreSql and is highly available
    const isPostgresHA =
      database?.databaseConfiguration?.createReplica &&
      database?.engine?.toUpperCase() === 'POSTGRESQL';

    // Get the list of permissions present
    const permissions = [];
    // rolesMap?.[ENTITY_TYPE.database] && databaseRoleMap[userRole]
    //   ? rolesMap[ENTITY_TYPE.database][databaseRoleMap[userRole]]
    //   : [];

    // Check if the service can be stopped
    const stopDb =
      features?.includes('stop-database') &&
      permissions?.includes('STOP_TESSELL_SERVICE') &&
      dbStatus === STATUS.Ready &&
      !isPostgresHA;

    // Check if the service can be started
    const startDb =
      features?.includes('start-database') &&
      permissions?.includes('START_TESSELL_SERVICE') &&
      dbStatus === STATUS.Stopped &&
      !isPostgresHA;

    // General database service updation can be allowed when the service is not in ready state
    // as it doesn't need DB login.
    const isAWSPrivateLinkEnabled =
      dbCloud === 'AWS' && dbStatus === STATUS.Ready;

    // Check if the service update is allowed from the backend
    const updatePermissionsPresent = permissions?.includes('UPDATE');

    // Check if general update is allowed for the current database status
    const isValidDBStateForGeneralUpdate =
      validDBStateForGeneralUpdate.includes(dbStatus);

    // Check if general update is allowed for the service from the backend in the current status
    const allowGeneralUpdate =
      updatePermissionsPresent && isValidDBStateForGeneralUpdate;

    // Check if tags update is enabled
    const allowTagsUpdate =
      updatePermissionsPresent &&
      (dbStatus === STATUS.Creating || isValidDBStateForGeneralUpdate);

    // Database update should only be allowed when the service is in Ready state
    const allowDatabaseUpdate =
      permissions?.includes('UPDATE_DATABASE') && dbStatus === STATUS.Ready;

    // Check if delete database is enabled
    const deleteDb =
      permissions?.includes('DELETE_DATABASE') &&
      dbStatus !== STATUS.Deleting &&
      dbStatus !== STATUS.Creating &&
      dbStatus !== STATUS.Stopping &&
      dbStatus !== STATUS.Starting &&
      dbStatus !== STATUS.Switching;

    // Should show AM to the end user
    const isAMVisible =
      userRole !== databaseRoleMap.READ_ONLY && dbStatus !== STATUS.Creating;

    // Check if scheduleing is enabled in a service
    const scheduling = true;

    // Check if the service is highly available and has ready status
    // const isMultiAzEnabled = Boolean(dbStatus === STATUS.Ready) && database.multiAZ;

    const monitoringFeature =
      features?.includes('database-monitoring') &&
      (dbStatus !== STATUS.Deleting || dbStatus !== STATUS.Creating);

    // Check if database logs tabs should be shown
    const logsFeature =
      features?.includes('database-logs') &&
      (dbStatus !== STATUS.Deleting || dbStatus !== STATUS.Creating);

    const isSharingDb =
      dbStatus !== STATUS.Deleting && permissions?.includes('SHARE');

    // Check if the service is read only or view only
    const isReadOnly =
      Boolean(dbStatus !== STATUS.Deleting) &&
      Boolean(dbStatus !== STATUS.Creating) &&
      Boolean(permissions?.includes('VIEW'));

    // Check if switchover is enabled
    const haNodeCount = getDatabaseHANodeCount(database);
    const drNodeCount = getDatabaseDRCount(database);
    const isSwitchoverNodePresent =
      (haNodeCount > 0 || drNodeCount > 0) && dbInstances?.length > 1;
    const isSwitchOverEnabled =
      isSwitchoverNodePresent && dbStatus === STATUS.Ready;

    // Check if databases are enabled
    const isDatabasesEnabled = dbEngineType !== 'ORACLE';

    // Check if connection pool is enabled
    const isPoolsEnabled = dbEngineType === 'POSTGRESQL';

    // const isAWSPrivateLinkEnabled = dbCloud === 'AWS';

    // Check if instance resize is enabled for this feature flag
    const isComputeResizeEnabled = shouldEnableInstanceResizeFeature(
      database,
      featureFlags?.instanceResize,
    );

    // Restore DB flag
    const restoreDb =
      permissions?.includes('RESTORE_TESSELL_SERVICE') &&
      dbStatus !== STATUS.Creating;

    // Add replica
    const isDatabaseHighlyAvailable = getDatabaseDCCount(database) > 1;

    // disable DR/RR for below versions
    const disableDRForEngineVersions =
      database?.softwareImageVersion?.includes('MySQL 5.6') ||
      database?.softwareImageVersion?.includes('MySQL 8.0.26');

    const disableAddReplica = isSSLEnabled || disableDRForEngineVersions;

    const showAddReplica =
      allowedEngineForDR.includes(dbEngineType) && !disableAddReplica;

    // only for oracle -- check if service is multi tenant i.e., it has been created as a container database
    const isOracleServiceMultiTenant =
      database?.engineConfiguration?.oracleConfig?.multiTenant;

    return {
      showAddReplica,
      restoreDb: true,
      stopDb: true,
      startDb: true,
      allowTagsUpdate: true,
      allowGeneralUpdate: true,
      allowDatabaseUpdate: true,
      deleteDb: true,
      monitoringFeature,
      logsFeature,
      isSharingDb,
      isAMVisible,
      isReadOnly,
      scheduling,
      // isMultiAzEnabled, Commented this as it's not being used anywhere in the code
      isMultiAz,
      isSwitchOverEnabled,
      isComputeResizeEnabled,
      isDatabasesEnabled,
      isAWSPrivateLinkEnabled,
      isPoolsEnabled,
      isOracleServiceMultiTenant,
    };
  };
}

export function SelectInstance(props) {
  const { instance, instances, setInstance } = props;
  return (
    <TextField
      select
      label="Database Instance"
      value={instance}
      variant="standard"
      size="small"
      sx={{ width: '300px' }}
      onChange={(e) => setInstance(e.target.value)}
      name="selectedNode"
    >
      {instances?.map((i) => {
        const role = InstanceRole[i?.role?.toLowerCase()] || '';
        return (
          <MenuItem key={i._id} value={i._id}>{`${i.name} (${role})`}</MenuItem>
        );
      })}
    </TextField>
  );
}

export const MODAL = {
  delete: 'delete',
  restore: 'restore',
  start: 'start',
  stop: 'stop',
  update: 'update',
  remove: 'remove',
  updateHost: 'updateHost',
  ip: 'ip',
  access: 'access',
  password: 'password',
  tags: 'tags',
  switchover: 'switchover',
  failover: 'failover',
  genie: 'genie',
  requestGenieDuration: 'requestGenieDuration',
  addDatabase: 'addDatabase',
  deleteDatabase: 'deleteDatabase',
  updateDatabase: 'updateDatabase',
  instanceResize: 'instanceResize',
  username: 'userName',
  privateLink: 'privateLink',
  modifyPool: 'modifyPool',
  deletePool: 'deletePool',
  poolInfo: 'poolInfo',
  AddReadReplica: 'AddReadReplica',
  AddDRReplica: 'AddDRReplica',
  connectStringGenerator: 'connectStringGenerator',
  viewCaCert: 'viewCaCert',
  // reboot: 'reboot',
  // delete_instance: 'delete_instance',
};

export const InstanceRole = {
  primary: 'Primary',
  dr: 'DR Replica (Manual Failover)',
  failover_replica: 'HA Replica (Automatic Failover)',
  read_only_replica: 'Read Replica',
};

export const formatBytesSize = (size) => {
  const units = [
    'bytes',
    'KiB',
    'MiB',
    'GiB',
    'TiB',
    'PiB',
    'EiB',
    'ZiB',
    'YiB',
  ];
  let l = 0;
  let n = parseInt(size, 10) || 0;

  while (n >= 1024 && ++l) {
    n /= 1024;
  }

  return `${n.toFixed(n < 10 && l > 0 ? 1 : 0)} ${units[l]}`;
};

export const ConnectOptions = {
  database: 'Database',
  pools: 'Pools',
};

export const serviceURLGenerator = (
  dbName,
  poolName,
  username,
  password,
  host,
  engineType,
  port,
  sslParams,
  enableSSL,
  connectDesc,
  isOracleServiceMultiTenant,
) => {
  switch (engineType) {
    case 'MYSQL': {
      const dbPool = dbName || poolName;
      const connectString = `mysql.connector.connect(user="${username}", password="${password}",host="${host}", port="${port}", database="${dbPool}", ${sslParams?.MySqlSslParams})`;
      return connectString;
    }

    case 'MONGODB': {
      const connectString = `mongodb://${username}:${password}@${host}:${port}/?authSource=admin`;
      return connectString;
    }

    case 'POSTGRESQL': {
      const dbPool = dbName || poolName;
      const connectString = `postgresql://${username}:${password}@${host}:${port}/${dbPool}?${sslParams?.PostgresSslParams}`;
      return connectString;
    }

    case 'ORACLE': {
      return connectDesc;
    }

    case 'SQLSERVER': {
      const connectString = `Server=${host},${port}; Database=${dbName}; User Id=${username}; Password=${password}; hostNameInCertificate=${host}`;

      return connectString;
    }

    default:
      return null;
  }
};

export const GetProperCloudList = (servicePlan) => {
  const ENGINE_LIST_PE = [
    {
      id: 'All',
      name: 'All',
    },
    {
      id: 'POSTGRESQL',
      name: 'PostgreSQL',
    },
    {
      id: 'MYSQL',
      name: 'MySQL',
    },
  ];

  const ENGINE_LIST_EE = [
    {
      id: 'All',
      name: 'All',
    },
    {
      id: 'ORACLE',
      name: 'Oracle',
    },
    {
      id: 'POSTGRESQL',
      name: 'PostgreSQL',
    },
    {
      id: 'MYSQL',
      name: 'MySQL',
    },
    {
      id: 'SQLSERVER',
      name: 'SQL Server',
    },
  ];

  const isPE = servicePlan === 'Professional Edition';

  return isPE ? ENGINE_LIST_PE : ENGINE_LIST_EE;
};

export const IsTsmAndHa = (database) => {
  const {
    compute: dbCompute,
    instances: dbInstances,
    multiAZ: isDbMultiAZ,
  } = database;

  const isComputeTSM = dbCompute?.tsm;

  // Check if service is highly available
  const isMultiAz = dbInstances?.length > 1 || isDbMultiAZ;

  // Check if the service is HA + Compute_TSM
  const isHaAndTsm = isComputeTSM && isMultiAz;

  return !!isHaAndTsm;
};
